@import "resources.scss";
.hotel-checkin-info {
  margin-top: 16px;

  &-time {
    @include caption;
    display: flex;
    align-items: center;

    i {
      font-size: 15px;
      margin-right: 4px;
    }
  }

  &-time + &-time {
    margin-top: 12px;
  }

  &-overlay {
    &-times {
      background: $grey50;
      border-radius: 8px;
      padding: 12px 0;
      display: flex;
      margin-bottom: 24px;

      &-inner {
        @include caption;

        padding: 0 24px 0 16px;
        display: flex;
        align-items: center;

        i {
          font-size: 20px;
          margin-right: 12px;
        }

        strong {
          @include body;
          font-weight: 500;
        }

        &:first-child {
          border-right: 1px solid $border-gray;
        }
      }
    }

    &-description {
      p {
        font-weight: 500;
        margin-top: 10px;
      }
      
      ol, ul {
        padding-left: 30px;
        list-style: disc;
      }
    }

    h2 {
      @include subtitle2;
      font-weight: 700;
      margin-bottom: 8px;
    }

    hr {
      margin: 16px 0;
      border: 0;
      border-top: 1px solid $border-gray;
    }
  }
}
